// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-articles-js": () => import("./../../../src/pages/articles.js" /* webpackChunkName: "component---src-pages-articles-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-races-js": () => import("./../../../src/pages/races.js" /* webpackChunkName: "component---src-pages-races-js" */),
  "component---src-pages-reviews-js": () => import("./../../../src/pages/reviews.js" /* webpackChunkName: "component---src-pages-reviews-js" */),
  "component---src-pages-routes-js": () => import("./../../../src/pages/routes.js" /* webpackChunkName: "component---src-pages-routes-js" */),
  "component---src-pages-site-blog-js": () => import("./../../../src/pages/site-blog.js" /* webpackChunkName: "component---src-pages-site-blog-js" */),
  "component---src-templates-blog-posts-js": () => import("./../../../src/templates/blog-posts.js" /* webpackChunkName: "component---src-templates-blog-posts-js" */),
  "component---src-templates-race-posts-js": () => import("./../../../src/templates/race-posts.js" /* webpackChunkName: "component---src-templates-race-posts-js" */)
}

